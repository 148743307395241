<template>
  <div>
    <content-block>
      <content-title slot="title" justify="space-between">
        <span>注册用户详情</span>
      </content-title>
      <el-card>
        <el-descriptions size="medium" :colon="false">
          <el-descriptions-item :span="3">
            <div class="info">
              <el-avatar :src="employeeDetail.headUrl" size="large"></el-avatar>
              <div class="name_and_status">
                <div class="name">{{ employeeDetail.name | commonFormat }}</div>
                <div
                  :class="{
                    non_auth: employeeDetail.authenticationStatus === '1',
                    auth_ing: employeeDetail.authenticationStatus === '2',
                    auth: employeeDetail.authenticationStatus === '3',
                  }"
                >
                  {{ authStatusEnum[employeeDetail.authenticationStatus] || '--' }}
                </div>
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="手机号：">
            {{ employeeDetail.phoneNumber | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="昵称：">
            {{ employeeDetail.nickName | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="性别：">
            {{ employeeDetail.gender | formatGender }}
          </el-descriptions-item>
          <el-descriptions-item label="生日：">
            {{ employeeDetail.birthday | formatDate }}
          </el-descriptions-item>
          <el-descriptions-item label="个人邮箱：">
            {{ employeeDetail.email | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="联系地址：" :span="3">
            {{ `${employeeDetail.contactProvinceName || ''}${employeeDetail.contactCityName || ''}${employeeDetail.contactAreaName || ''}${employeeDetail.contactAddrs || ''}` | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="注册来源：">
            {{ registerSourceEnum[employeeDetail.registerSource] | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="注册时间：">
            {{ employeeDetail.createTime | formatDateTime }}
          </el-descriptions-item>
          <el-descriptions-item label="注册方式：">
            {{ registerTypeEnum[employeeDetail.registerType] | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="用户状态：">
            {{ registerStatusEnum[employeeDetail.registerStatus] | commonFormat }}
          </el-descriptions-item>
          <el-descriptions-item label="个性签名：" :span="3">
            {{ employeeDetail.personalSignature | commonFormat }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane label="实名认证" name="authorize">
          <el-descriptions size="medium" :colon="false">>
            <el-descriptions-item label="真实姓名：" :span="12">
              {{ employeeDetail.name | commonFormat }}
            </el-descriptions-item>
            <el-descriptions-item label="身份证效期：" :span="12">
              {{ employeeDetail.idCardExpirationDate | formatDate }}
            </el-descriptions-item>
            <el-descriptions-item label="手持有效身份证照片：" :span="12">
              <el-image
                :preview-src-list="prevLists"
                v-for="img in employeeDetail.idCardList"
                style="width: 120px; height: 80px;margin-right: 10px;"
                :key="img.annexUrl"
                :src="img.annexUrl"
                fit="fill">
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="关联企业" name="relatedCompany">
          <table-with-pagination
            :tableData="employeeDetail.tenantRespDTOList"
            class="content_block"
            :columnList="tenantColumnList"
          />
        </el-tab-pane>
      </el-tabs>
    </content-block>
  </div>
</template>
<script>
import { getEmployeeDetail } from '@/api/tenant';
import * as dayjs from 'dayjs';
import authStatusEnum from '@/model/xzpt/auth';
import registerStatusEnum from '@/model/xzpt/registerStatus';
import registerSourceEnum from '@/model/xzpt/registerSource';
import registerTypeEnum from '@/model/xzpt/registerType';
import defaultAvatar from '@/assets/imgs/avatar.svg';
import { dateFormat } from '@/utils/dateFormat';
import TableWithPagination from '@/components/common/TableWithPagination';

export default {
  name: 'EMPLOYEE_DETAIL',
  props: {
    id: {
      type: String,
    },
    auditFlag: {
      type: String,
    },
  },
  components: {
    TableWithPagination,
  },
  data () {
    return {
      prevLists: [],
      employeeDetail: {
        imageList: [],
      },
      activeName: 'authorize',
      approveMsg: '',
      registerStatusEnum,
      registerSourceEnum,
      registerTypeEnum,
      authStatusEnum,
      tenantColumnList: [
        {
          tooltip: true,
          label: '企业名称',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '企业编号',
          minWidth: '150px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '企业认证状态',
          minWidth: '100px',
          prop: 'authenticationStatus',
          format: v => {
            return authStatusEnum[v];
          },
        },
        {
          tooltip: true,
          label: '公司职务',
          minWidth: '150px',
          prop: 'phoneNumber',
        },
        {
          tooltip: true,
          label: '认证时间',
          minWidth: '150px',
          prop: 'authenticationTime',
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
      ],
    };
  },
  created () {
    this.id
      && getEmployeeDetail({
        id: this.id,
        findIdCardFlag: true,
        findAssociatedTenantFlag: true,
        findTenantFlag: true,
      }).then(res => {
        (res.body.idCardList || []).sort((a, b) => Number(a).type - Number(b).type);
        this.prevLists = (res.body.idCardList || []).map(item => item.annexUrl);
        this.employeeDetail = res.body;

        this.employeeDetail.headUrl = this.employeeDetail.headUrl || defaultAvatar;
      });
  },
  filters: {
    formatDateTime: val => {
      return val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
    formatDate: val => {
      const dateStr = val ? dayjs(val).format('YYYY-MM-DD') : null;
      return dateStr === '2099-12-31' ? '永久' : dateStr || '--';
    },
    commonFormat: val => {
      return val || '--';
    },
    formatGender: val => {
      return val === '1' ? '男' : val === '2' ? '女' : '保密';
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-descriptions-item__content {
  .info {
    display: flex;
    .name_and_status {
      margin-left: 6px;
      font-size: 12px;
      font-weight: bold;
      .name {
        font-size: 14px;
      }
      .non_auth {
        color: #909399;
      }

      .auth_ing {
        color: #E6A23C;
      }

      .auth {
        color: #67C23A;
      }
    }

    .el-avatar > img {
      width: 100%;
    }
  }
}
</style>
